




























import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalDefaultExitCondition from '@/components/organisms/ModalDefaultExitCondition.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    TableBase,
    ModalDefaultExitCondition,
  },
})
export default class StudentExitCondition extends Vue {
  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '生徒', href: '/teacher/setting/student' },
    { text: '終了条件', active: true },
  ]

  private tableBasePerPage?: number

  private tableBaseChallengeItems = [
    {
      subject: '英語',
      item: '問題数',
      limit: '5問',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '国語',
      item: '問題数',
      limit: '5問',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '数学',
      item: '問題数',
      limit: '5問',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '理科',
      item: '問題数',
      limit: '5問',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '社会',
      item: '問題数',
      limit: '5問',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
  ]

  private tableBaseHomeworkItems = [
    {
      subject: '英語',
      item: '時間',
      limit: '30分',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '国語',
      item: '時間',
      limit: '30分',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '数学',
      item: '時間',
      limit: '30分',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '理科',
      item: '時間',
      limit: '30分',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
    {
      subject: '社会',
      item: '時間',
      limit: '30分',
      action: [{ onclick: this.showDefaultExitCondition, name: '編集' }],
    },
  ]

  private tableBaseFields = [
    { key: 'subject', label: '教科' },
    { key: 'item', label: '制限項目' },
    { key: 'limit', label: '制限しきい値' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons = ['action']

  private studentDatas = [
    { id: 1, name: 'ビーコン太郎' },
    { id: 2, name: 'ビーコン次郎' },
  ]

  private showDefaultExitCondition(): void {
    this.$modal.show('modal-default-exit-condition')
  }
}
